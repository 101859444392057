<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Control de limpieza
            </div>

            <!-- <v-row>
                <v-col cols="4" sm="7" md="5" lg="4" xl="3">
                    Filtro
                </v-col>
            </v-row> -->

            <v-btn
                color="primary"
                class="mt-5 mb-3"
                @click="onClickNuevoControl">
                Nuevo
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarControl"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.LimTmp="{ item }">
                    {{ formatDate(item.LimTmp) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.ElemNoms="{ item }">
                    {{ item.ElemNoms.join(', ') }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.LimElemFin="{ item }">
                    <v-simple-checkbox v-model="item.LimElemFin" disabled></v-simple-checkbox>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.LimObs="{ item }">
                    <v-simple-checkbox v-model="item.LimObs" disabled></v-simple-checkbox>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz']),
        isMartin: function () {
          return (this.empId == 8 || this.empId == 12 || this.empId == 15);
        },
        defaultPerIdLim: function () {
          return this.isMartin ? 
            29904 : // LIMPIEZAS BARCINO 
            7258; // LIMPIEZAS AGUAVIVA
        }
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha', width:105, sortable: false, value: 'LimTmp' },
                { text: 'Tienda', width:225, sortable: false, value: 'Dir.DirNom' },
                // { text: 'Tipo', width:225, sortable: false, value: 'ElemCat.ElemCatNom' },
                { text: 'Tipo', width:210, sortable: false, value: 'ElemNoms' },
                { text: 'Empresa de limpieza', width:175, sortable: false, value: 'PerLim.PerNom' },
                { text: 'Controlado por', width:225, sortable: false, value: 'Per.PerNom' },
                { text: 'Conforme', width:85, sortable: false, value: 'LimElemFin', align: 'center' },
                { text: 'Observaciones', width:100, sortable: false, value: 'LimObs', align: 'center' }
            ],
            dtOptionsLoaded: false,
            // filtros: {
            // }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            DirId: this.$store.state.dir.DirId,
            EmpId: this.empId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/lim", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                  items,
                  total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar los controles de limpieza.');
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items
          this.totalDataRows = data.total
        });
      },
      onClickNuevoControl() {
        this.setDefaultData();
        this.$router.push('/control-limpieza-nuevo/');
      },
      onClickRowEditarControl(item) {
        this.setDefaultData();
        this.$router.push('/control-limpieza-editar/' + item.LimId);
      },
      setDefaultData () {
        this.$store.state.DefaultElemCatId = 1; // EXTERIOR ER
        this.$store.state.LoadLimElems = false;
        this.$store.state.DefaultPerIdLim = this.defaultPerIdLim;
      },
      formatDate (value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
      }
    } 
})
</script>
