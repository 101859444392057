var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',{staticClass:"text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center"},[_vm._v(" Control de limpieza ")]),_c('v-btn',{staticClass:"mt-5 mb-3",attrs:{"color":"primary"},on:{"click":_vm.onClickNuevoControl}},[_vm._v(" Nuevo ")]),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"overflow":"scroll"},attrs:{"headers":_vm.headers,"items":_vm.dataRows,"options":_vm.options,"footer-props":{itemsPerPageOptions: [15, 30, 50, 100]},"server-items-length":_vm.totalDataRows,"loading":_vm.loading,"mobile-breakpoint":"0","dense":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onClickRowEditarControl},scopedSlots:_vm._u([{key:"item.LimTmp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.LimTmp))+" ")]}},{key:"item.ElemNoms",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ElemNoms.join(', '))+" ")]}},{key:"item.LimElemFin",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.LimElemFin),callback:function ($$v) {_vm.$set(item, "LimElemFin", $$v)},expression:"item.LimElemFin"}})]}},{key:"item.LimObs",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.LimObs),callback:function ($$v) {_vm.$set(item, "LimObs", $$v)},expression:"item.LimObs"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }